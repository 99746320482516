<template>
  <div>
    <template v-if="type === 'slider'">
      <LayoutSwipeList class="mb-10">
        <SwiperSlide v-for="(item, index) in items" :key="index">
          <BirthdayTeaser :item="item" :index="index" type="slider" />
        </SwiperSlide>
      </LayoutSwipeList>
    </template>
    <template v-else>
      <CommonListLabel
        v-if="placement === 'side'"
        label="Birthdays"
        :link="
          false
            ? undefined
            : currentPaperUrl + '/' + $t('Routes.Birthdays')
        "
      />
      <CommonDivider />

      <ul class="text-red">
        <template v-for="(item, index) in items" :key="'li' + index">
          <li>
            <BirthdayTeaser
              :item="item"
              :placement="placement"
              :index="index"
            />
            <CommonDivider />
          </li>
        </template>
      </ul>
    </template>
  </div>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'

const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()

const props = withDefaults(
  defineProps<{ count?: number; type?: string; placement?: string }>(),
  {
    count: 5,
    type: 'default',
    placement: 'side',
  }
)

const { data: items } = await useAsyncData(
  `${props.count}-${indexStore.currentPaperIdentifier}`,
  async () => {
    const rawResponse = await nuxtApp.$api.content.birthdays(
      indexStore.currentPaperIdentifier,
      props.count
    )

    const birthdayList = rawResponse.filter((item) => {
      if (!item.urlkey) {
        return false
      }

      return true
    })

    return birthdayList
  }
)

const currentPaperUrl = computed(() => {
  return indexStore.currentPaper?.RecordId === 1
    ? ''
    : `/${indexStore.currentPaper?.Url}`
})
</script>
